.typing-named {
  display: inline-flex;
  flex-direction: column;

  span {
    background-image: url('https://cdn.pikalytics.com/images/types.png');
    display: inline-block;
    width: 66px;
    height: 19px * 0.75;
    margin: 1px;
    background-size: 540px * 0.75;
  }

  .type-name-normal {
    background-position: -1px * 0.75 -7px * 0.75;
  }
  .type-name-fighting {
    background-position: -91px * 0.75 -7px * 0.75;
  }
  .type-name-flying {
    background-position: -181px * 0.75 -7px * 0.75;
  }
  .type-name-poison {
    background-position: -271px * 0.75 -7px * 0.75;
  }
  .type-name-ground {
    background-position: -361px * 0.75 -7px * 0.75;
  }
  .type-name-rock {
    background-position: -451px * 0.75 -7px * 0.75;
  }

  .type-name-bug {
    background-position: -1px * 0.75 -27px * 0.75;
  }
  .type-name-ghost {
    background-position: -91px * 0.75 -27px * 0.75;
  }
  .type-name-steel {
    background-position: -181px * 0.75 -27px * 0.75;
  }
  .type-name-fire {
    background-position: -271px * 0.75 -27px * 0.75;
  }
  .type-name-water {
    background-position: -361px * 0.75 -27px * 0.75;
  }
  .type-name-grass {
    background-position: -451px * 0.75 -27px * 0.75;
  }

  .type-name-electric {
    background-position: -1px * 0.75 -47px * 0.75;
  }
  .type-name-psychic {
    background-position: -91px * 0.75 -47px * 0.75;
  }
  .type-name-ice {
    background-position: -181px * 0.75 -47px * 0.75;
  }
  .type-name-dragon {
    background-position: -271px * 0.75 -47px * 0.75;
  }
  .type-name-dark {
    background-position: -361px * 0.75 -47px * 0.75;
  }
  .type-name-fairy {
    background-position: -451px * 0.75 -47px * 0.75;
  }
}

.typing-icons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .type-icon {
    width: 18px;
  }
}
