@use 'types';

body {
  background-color: #10101c;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  // height: 100%;
}

#tiers,
#users {
  display: flex;
  flex-flow: row;
}

.pokemon {
  .point-cost {
    font-weight: 400;
    font-style: italic;
    font-size: 0.9em;
    width: 6.7em;
  }
  &.picked {
    opacity: 0.4;
  }
  display: flex;
  flex-direction: row;
  position: relative;

  * {
    margin: 0 2px;
  }

  &:nth-child(even) {
    background-color: #1d1d2b;
  }

  .typing-named {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .name {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1.2em;
  }
  .icon {
    background-image: url('https://play.pokemonshowdown.com/sprites/pokemonicons-sheet.png?v4');
    display: inline-block;
    width: 40px;
    height: 30px;
    vertical-align: bottom;
  }
  .stats,
  .point-cost {
    color: #999;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .stats {
    margin: 0 8px;
    font-size: 0.8em;
  }
  .abilities {
    list-style-type: none;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // margin-left: 8px;
    padding-left: 0;
    color: #ddd;
  }
  .button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid green;
    margin: 6px 0;
    padding: 0 4px;
    text-decoration: none;
    border-radius: 3px;
    color: green;
    &:hover {
      background-color: green;
      color: black;
    }
    margin-left: auto;
  }
}

.pick-section {
  width: max-content;
  padding: 10px;
  display: flex;
  flex-flow: column;
  > div {
    height: 100%;
  }
  &.active {
    order: -1;
  }
}

#users {
  > :not(:first-child) {
    border-left: 1px solid #555;
  }
  > :last-child {
    border-right: 2px solid #777;
    margin-right: 16px;
    padding-right: 16px;
  }
}

#tiers > .pick-section {
  border-right: 1px solid #555;
}

.point-cost {
  font-size: 0.5em;
  font-weight: 300;
}

#app {
  display: flex;
  flex-flow: row;

  position: absolute;
  min-height: 100%;
  // width: 100%;
  box-sizing: border-box;
  padding: 8px;
  font-size: 12px;
}
